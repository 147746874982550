import { createGlobalStyle } from "styled-components";

export const BORDER_RADIUS = "var(--border-radius)";
export const HEADER_Y_MARGIN = "var(--header-y-margin)";
export const NAVBAR_PADDING = "var(--navbar-padding)";
export const PANEL_BG_COLOR = "var(--panel-bg-color)";
export const PANEL_PADDING = "var(--panel-padding)";
export const PANEL_PADDING_NEG = "var(--panel-padding-neg)";
export const P_SPACING = "var(--p-spacing)";
export const TEXT_COLOR = "var(--text-color)";
export const TITLE_COLOR = "var(--title-color)";
export const LINK_COLOR = "var(--link-color)";
export const LINE_HEIGHT = "var(--line-height)";
export const BOX_SHADOW_COLOR = "var(--box-shadow-color)";
export const BOX_SHADOW = "var(--box-shadow)";

export const GlobalStyle = createGlobalStyle`
  :root {
    --border-radius: 0px;
    --header-y-margin: 40px;
    --navbar-padding: 20px;
    --panel-bg-color: rgba(45, 45, 47, 1);
    --panel-padding: 40px;
    --panel-padding-neg: calc(var(--panel-padding) * -1);
    --p-spacing: 30px;
    --text-color: rgba(204, 204, 204, 1);
    --title-color: rgba(245, 245, 245, 1);
    --link-color: rgba(77, 181, 255, 1);
    --highlight-color: rgba(77, 181, 255, 0.1);
    --line-height: 1.65;
    --box-shadow-color: rgba(0, 0, 0, 0.12);
    --box-shadow: 0px 1px 1px var(--box-shadow-color),
    0px 2px 2px var(--box-shadow-color),
    0px 4px 4px var(--box-shadow-color),
    0px 8px 8px var(--box-shadow-color),
    0px 16px 16px var(--box-shadow-color);
  }

  @media screen and (max-width: 400px) {
    :root {
      --panel-padding: 20px;
    }
  }
  
  @font-face {
    font-family:"Raleway";
    src: url("/fonts/raleway400.woff2") format("woff2"),url("/fonts/raleway400.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
  }

  @font-face {
    font-family:"Raleway";
    src: url("/fonts/raleway600.woff2") format("woff2"),url("/fonts/raleway600.woff") format("woff");
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Metropolis-Light.woff2') format('woff2'),
    url('/fonts/Metropolis-Light.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Metropolis-SemiBold.woff2') format('woff2'),
    url('/fonts/Metropolis-SemiBold.woff') format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Cousine';
    font-style: normal;
    src: url('/fonts/Cousine.woff2') format('woff2');
    font-display: swap;
  }

  h1 {
    font-size: 2.2em;
  }

  h2 {
    font-size: 1.6em;
  }

  h3 {
    font-size: 1.2em;
  }

  body {
    background-color: rgb(53, 53, 57);
    background-repeat: repeat;
    background-position: left top;
    background-image: url("/background.svg");
    background-size: 480px 480px;

    font-family: "Raleway", "Tahoma", "Helvetica", "Arial", sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    font-size: 20px;
    margin: 0;
  }

  p, li {
    font-family: "Metropolis", "Tahoma", "Helvetica", "Arial", sans-serif;
  }

  a {
    color: #4db5ff;
    text-decoration: none;
  }

  a:hover {
    color: #ff4d4d;
    text-decoration: none;
  }

  @media screen and (max-width: 650px) {
    body {
      margin-left: 0;
      margin-right: 0;
    }
  }

  main {
    max-width: 960px;
    margin: 0 auto 0 auto;
  }

  header {
    margin: var(--header-y-margin) var(--panel-padding);
  }

  footer {
    margin: var(--panel-padding);
    color: #5c5c5c;
  }

  section h2, section h3 {
    margin-top: 0;
  }

  article > h2, article > h3 {
    margin-left: var(--panel-padding);
  }

  section :last-child {
    margin-bottom: 0;
  }

  section p:first-child {
    margin-top: 0;
  }
  
  article h1, article h2, article h3, article h4, article h5, header h1, header h2 {
    color: #f5f5f5;
  }
  
  blockquote {
    margin-left: 0;
    border-left: 8px solid var(--highlight-color);
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* Links in "Home" and "Contact" */
  #links .name {
    display: inline-block;
  }

  #links .link {
    display: inline-block;
    padding-left: 25px;
    width: 50%;
  }

  hr {
    border: 1px #494950 solid;
    margin-top: 25px;
    margin-bottom: 50px;
  }

  /* CODE */
  pre {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px var(--panel-padding);
    margin: 20px var(--panel-padding);
    overflow-x: auto;
    box-sizing: content-box;
    font-size: 90%;
  }

  pre::-webkit-scrollbar {
    height: 12px;
  }

  pre::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
  }

  pre::-webkit-scrollbar-thumb:hover,
  pre::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, .4)
  }

  figure {
    margin-left: var(--panel-padding-neg);
    margin-right: var(--panel-padding-neg);
  }

  code {
    font-family: 'Cousine', monospace;
    font-size: 85%;
    background-color: rgba(0,0,0,0.2);
    padding: 3px 4px 1px 4px;
    margin: 0 1px;
  }

  pre > code {
    font-size: 90%;
    color: #abb2bf;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  /**
   * Syntax highlighting styles
   */
  /**
   * prism.js default theme for JavaScript, CSS and HTML
   * Based on dabblet (http://dabblet.com)
   * @author Lea Verou
   */
  code[class*="language-"],
  pre[class*="language-"] {
    color: #ABB2BF;
    background: none;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
  code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #383e49;
  }

  pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
  code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: #9aa2b1;
  }

  @media print {
    code[class*="language-"],
    pre[class*="language-"] {
      text-shadow: none;
    }
  }
  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #282c34;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #5C6370;
  }

  .token.punctuation {
    color: #abb2bf;
  }

  .token.selector,
  .token.tag {
    color: #e06c75;
  }

  .token.property,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.attr-name,
  .token.deleted {
    color: #d19a66;
  }

  .token.string,
  .token.char,
  .token.attr-value,
  .token.builtin,
  .token.inserted {
    color: #98c379;
  }

  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #56b6c2;
  }

  .token.atrule,
  .token.keyword {
    color: #c678dd;
  }

  .token.function {
    color: #61afef;
  }

  .token.regex,
  .token.important,
  .token.variable {
    color: #c678dd;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }

  pre.line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
  }

  pre.line-numbers > code {
    position: relative;
  }

  .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em; /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 0;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  .line-numbers-rows > span {
    pointer-events: none;
    display: block;
    counter-increment: linenumber;
  }

  .line-numbers-rows > span:before {
    content: counter(linenumber);
    color: #5C6370;
    display: block;
    padding-right: 0.8em;
    text-align: right;
  }

  /* Customizations */

  .token.tag.script,
  .token.tag.parameter,
  .token.tag.class-name {
    color: #d19a66;
  }

  .token.tag.operator,
  .token.tag.punctuation {
    color: #abb2bf;
  }

  .token.comment {
    font-style: italic;
  }
`;
