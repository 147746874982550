import type { AppProps } from "next/app";
import { useRouter } from "next/router";

import { GlobalStyle } from "../components/layout/GlobalStyle";

const App = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter();
  return (
    <>
      <GlobalStyle />
      <Component key={locale} {...pageProps} />
    </>
  );
};

export default App;
